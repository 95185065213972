var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "credit_reports_main" },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeTab,
            callback: function($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "基本信息", name: "basicInformation" } },
            [_c("basic-info", { attrs: { data: this.basicInformation } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "身份标识", name: "identification" } },
            [_c("identification", { attrs: { data: this.identification } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "信息概要", name: "informationSummary" } },
            [
              _c("information-summary", {
                attrs: { data: this.informationSummary }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }