
  import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
  import { selectByParam } from '@/api/filing'
  import BasicInfo from '@/views/project-management/ocr/table/credit-reports/BasicInfo.vue'
  import Identification
    from '@/views/project-management/ocr/table/credit-reports/Identification.vue'
  import InformationSummary
    from '@/views/project-management/ocr/table/credit-reports/InformationSummary.vue'

  @Component({
    components: {
      BasicInfo,
      Identification,
      InformationSummary
    },
    filters: {}
  })

  export default class CreditReports extends Vue {
    @Prop({ default: '' }) fileType!: string
    @Prop({ default: '' }) companyOrPersonal!: string
    @Prop({ default: true }) dialogVisible?: boolean
    private basicInformation: any = {}
    private identification: any = {}
    private informationSummary: any = {}
    private activeTab: any = 'basicInformation'

    @Watch('dialogVisible')
    private dialogVisibleWatch(newVal: boolean, oldVal: boolean) {
      this.viewResults()
      console.log(newVal + ' ,' + oldVal)
    }

    created() {
      this.viewResults()
    }

    private viewResults() {
      const params = {
        fileType: this.fileType,
        companyOrPersonal: this.companyOrPersonal
      }
      selectByParam(params as any)
        .then((res) => {
          // console.log('res =' + JSON.stringify(res))
          if (res.data.data) {
            this.basicInformation = res.data.data.basicInformation
            this.identification = res.data.data.identification
            this.informationSummary = res.data.data.informationSummary
            // 添加空格
            let dataList2: any = this.informationSummary.data2.dataList
            let dataList3: any = this.informationSummary.data3.dataList
            let len2: number = dataList2.length
            let len3: number = dataList3.length
            if (len2 > len3) {
              for (let i = 0; i < (len2 - len3); i++) {
                dataList3.push({})
              }
            } else {
              for (let i = 0; i < (len3 - len2); i++) {
                dataList2.push({})
              }
            }
            this.informationSummary.data2.dataList = dataList2
            this.informationSummary.data3.dataList = dataList3
          } else {
            this.basicInformation = null
            this.identification = null
            this.informationSummary = null
          }
        })
        .finally(() => {
        })
    }
  }
