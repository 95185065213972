
  import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
  import AssetLiability
    from '@/views/project-management/ocr/table/financial-statement/AssetLiability.vue'
  import CashFlow from '@/views/project-management/ocr/table/financial-statement/CashFlow .vue'
  import Profit from '@/views/project-management/ocr/table/financial-statement/Profit.vue'
  import { selectByParam } from '@/api/filing'

  @Component({
    components: {
      AssetLiability,
      CashFlow,
      Profit
    },
    filters: {}
  })

  export default class VueTSTemplate extends Vue {
    @Prop({ default: '' }) fileType!: string
    @Prop({ default: '' }) companyOrPersonal!: string
    @Prop({ default: true }) dialogVisible?: boolean
    private cashFlowStatement: any = {} //现金流量表
    private incomeStatement: any = {} //利润表
    private balanceSheet: any = {}  //资产负债表
    private activeTab: any = 'balanceSheet'

    @Watch('dialogVisible')
    private dialogVisibleWatch(newVal: boolean, oldVal: boolean) {
      this.viewResults()
      console.log(newVal + ' ,' + oldVal)
    }

    created() {
      this.viewResults()
    }

    private viewResults() {
      const params = {
        fileType: this.fileType,
        companyOrPersonal: this.companyOrPersonal
      }
      selectByParam(params as any)
        .then((res) => {
          // console.log('res =' + JSON.stringify(res))
          if (res.data.data) {
            this.cashFlowStatement = res.data.data.cashFlowStatement
            this.incomeStatement = res.data.data.incomeStatement
            this.balanceSheet = res.data.data.balanceSheet
          } else {
            this.cashFlowStatement = null
            this.incomeStatement = null
            this.balanceSheet = null
          }
        })
        .finally(() => {
        })
    }
  }
