var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "cash_flow_main" },
    [
      _vm._m(0),
      _vm._l(_vm.data.data, function(item, index) {
        return _c("div", { key: index, staticClass: "table_row2" }, [
          _c("div", { staticClass: "box1" }, [_vm._v(_vm._s(index))]),
          _c("div", { staticClass: "box2" }, [_vm._v(_vm._s(item.item))]),
          _c("div", { staticClass: "box3" }, [
            _vm._v(_vm._s(item.currentBalance))
          ]),
          _c("div", { staticClass: "box3" }, [_vm._v(_vm._s(item.lastBalance))])
        ])
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "table_row1" }, [
      _c("div", { staticClass: "box1" }, [_vm._v("行次")]),
      _c("div", { staticClass: "box2" }, [_vm._v("项目")]),
      _c("div", { staticClass: "box3" }, [_vm._v("本月金额")]),
      _c("div", { staticClass: "box3" }, [_vm._v("上期金额")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }