var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "info_main" }, [
    _c("div", { staticClass: "info_item" }, [
      _vm._m(0),
      _vm.newDataList && _vm.newDataList.length > 0
        ? _c(
            "div",
            { staticClass: "data_wrapper" },
            _vm._l(_vm.newDataList, function(item, index) {
              return _c(
                "div",
                { key: index, staticClass: "data_list_wrapper" },
                [
                  _c("span", { staticClass: "data_need" }, [
                    _vm._v(_vm._s(item.role) + "：")
                  ]),
                  _c(
                    "div",
                    { staticClass: "data_list" },
                    _vm._l(item.list, function(it, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "data_item" },
                        [
                          _c("span", { staticClass: "data_title" }, [
                            _vm._v(_vm._s(it.fileName))
                          ]),
                          it.ocrStatus === 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "btn_ocr",
                                  on: {
                                    click: function($event) {
                                      return _vm.gotoOCR(
                                        it.fileAddr,
                                        it.fileName
                                      )
                                    }
                                  }
                                },
                                [_vm._v("文字识别")]
                              )
                            : _vm._e()
                        ]
                      )
                    }),
                    0
                  )
                ]
              )
            }),
            0
          )
        : _c("div", { staticClass: "no_data" }, [_vm._v("无")])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("相关资料")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }