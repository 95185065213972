var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "information_summary_main" }, [
    _c("div", { staticClass: "table1_main" }, [
      _c("div", { staticClass: "table_main" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "table_body" },
          _vm._l(_vm.data.data1, function(item, index) {
            return _c("div", { key: index, staticClass: "content_box" }, [
              _vm._v(_vm._s(item.value) + " ")
            ])
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "table2_main" }, [
      _c("div", { staticClass: "table_main" }, [
        _vm._m(1),
        _c(
          "div",
          { staticClass: "table_body" },
          _vm._l(_vm.data.data2.dataList, function(item, index) {
            return _c("div", { key: index, staticClass: "table_row" }, [
              _c("div", { staticClass: "content_box" }, [
                _vm._v(" " + _vm._s(item.item) + " ")
              ]),
              _c("div", { staticClass: "content_box" }, [
                _vm._v(" " + _vm._s(item.balance) + " ")
              ])
            ])
          }),
          0
        )
      ]),
      _c("div", { staticClass: "table_main" }, [
        _vm._m(2),
        _c(
          "div",
          { staticClass: "table_body" },
          _vm._l(_vm.data.data3.dataList, function(item, index) {
            return _c("div", { key: index, staticClass: "table_row" }, [
              _c("div", { staticClass: "content_box" }, [
                _vm._v(" " + _vm._s(item.item) + " ")
              ]),
              _c("div", { staticClass: "content_box" }, [
                _vm._v(" " + _vm._s(item.balance) + " ")
              ])
            ])
          }),
          0
        )
      ])
    ]),
    _c("div", { staticClass: "table3_main" }, [
      _c("div", { staticClass: "table_main" }, [
        _vm._m(3),
        _c(
          "div",
          { staticClass: "table_body" },
          _vm._l(_vm.data.data4, function(item, index) {
            return _c("div", { key: index, staticClass: "content_box" }, [
              _vm._v(_vm._s(item.value) + " ")
            ])
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "table_header" }, [
      _c("div", { staticClass: "title_box" }, [_vm._v("首次有信贷交易的年份")]),
      _c("div", { staticClass: "title_box" }, [_vm._v("发生信贷交易的机构数")]),
      _c("div", { staticClass: "title_box" }, [
        _vm._v("当前有未结清信贷交易的机构数")
      ]),
      _c("div", { staticClass: "title_box" }, [
        _vm._v("首次有相关还款责任的年份")
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "table_header" }, [
      _c("div", { staticClass: "title_box" }, [_vm._v("借贷交易")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "table_header" }, [
      _c("div", { staticClass: "title_box" }, [_vm._v("担保交易")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "table_header" }, [
      _c("div", { staticClass: "title_box" }, [_vm._v("非信贷交易账户数")]),
      _c("div", { staticClass: "title_box" }, [_vm._v("欠税记录条数")]),
      _c("div", { staticClass: "title_box" }, [_vm._v("民事判决记录条数")]),
      _c("div", { staticClass: "title_box" }, [_vm._v("强制执行记录条数")]),
      _c("div", { staticClass: "title_box" }, [_vm._v("行政处罚记录条数")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }