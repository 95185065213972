var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "basicinfo_main" }, [
    _c("p", [_vm._v("基本概况信息")]),
    _c(
      "div",
      { staticClass: "base_info" },
      _vm._l(_vm.data.data1.dataList, function(item, index) {
        return _c("div", { key: index, staticClass: "table_row" }, [
          _c("div", { staticClass: "box1" }, [_vm._v(_vm._s(item.item))]),
          _c("div", { staticClass: "box2" }, [_vm._v(_vm._s(item.value))]),
          _c("div", { staticClass: "box1" }, [_vm._v(_vm._s(item.source))]),
          _c("div", { staticClass: "box2" }, [_vm._v(_vm._s(item.sourceValue))])
        ])
      }),
      0
    ),
    _c("p", [_vm._v("主要组成人员信息")]),
    _c(
      "div",
      { staticClass: "member_info" },
      _vm._l(_vm.data.data2.dataList, function(item, index) {
        return _c("div", { key: index, staticClass: "table_row" }, [
          _c("div", { staticClass: "box1" }, [_vm._v(_vm._s(item.item))]),
          _c("div", { staticClass: "box2" }, [_vm._v(_vm._s(item.value))]),
          _c("div", { staticClass: "box1" }, [_vm._v(_vm._s(item.source))]),
          _c("div", { staticClass: "box2" }, [_vm._v(_vm._s(item.sourceValue))])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }