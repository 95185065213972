
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import { selectByParam } from '@/api/filing'

  @Component({
    components: {},
    filters: {}
  })

  export default class Constitution extends Vue {
    @Prop({ default: '' }) fileType!: string
    @Prop({ default: '' }) companyOrPersonal!: string
    @Prop({ default: true }) dialogVisible?: boolean
    private dataList: any = {}

    @Watch('dialogVisible')
    private dialogVisibleWatch(newVal: boolean, oldVal: boolean) {
      this.viewResults()
      console.log(newVal + ' ,' + oldVal)
    }

    created() {
      this.viewResults()
    }

    private viewResults() {
      const params = {
        fileType: this.fileType,
        companyOrPersonal: this.companyOrPersonal
      }
      selectByParam(params as any)
        .then((res) => {
          debugger
          if (res.data.data) {
            this.dataList = res.data.data.dataList.dataList
          } else {
            this.dataList = null
          }
        })
        .finally(() => {
        })
    }
  }
