
  import { Component, Vue, Prop } from 'vue-property-decorator'

  @Component({
    components: {}
  })

  export default class Info extends Vue {
    private newDataList: any = []
    @Prop({ default: [], type: Array }) private dataList!: Array<Object>

    created() {

      let roleList: any = []
      this.dataList.forEach((item: any) => {
        if (item.role && !roleList.includes(item.role)) {
          roleList.push(item.role)
        }
      })
      roleList.forEach((item: any) => {
        let itemObj: any = {
          role: '',
          list: []
        }
        itemObj.role = item
        this.dataList.forEach((it: any) => {
          if (it.role === item) {
            itemObj.list.push(it)
          }
        })
        this.newDataList.push(itemObj)
      })
    }

    private gotoOCR(fileAddr: any, fileName: any) {
      debugger
      this.$router.push({
        name: 'Operation', params: {
          fileAddr: fileAddr,
          fileName: fileName,
        }
      })
    }
  }
