var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "financial_statement_main" },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeTab,
            callback: function($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "资产负债表", name: "balanceSheet" } },
            [_c("asset-liability", { attrs: { data: this.balanceSheet } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "利润表", name: "incomeStatement" } },
            [_c("profit", { attrs: { data: this.incomeStatement } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "现金流量表", name: "cashFlowStatement" } },
            [_c("cash-flow", { attrs: { data: this.cashFlowStatement } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }