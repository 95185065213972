
  import { Component, Vue, Prop } from 'vue-property-decorator'

  @Component({
    components: {},
    filters: {}
  })

  export default class BasicInfo extends Vue {
    @Prop() data!: any

    created() {

    }
  }
