var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "details" },
    [
      _c("div", { staticClass: "part first" }, [
        _vm._m(0),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "item" }, [
            _c("span", [_vm._v("企业名称：")]),
            _c("span", [_vm._v(_vm._s(_vm.info.names ? _vm.info.names : ""))])
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", [_vm._v("统一信用代码：")]),
            _c("span", [_vm._v(_vm._s(_vm.info.code))])
          ])
        ]),
        _vm._m(1),
        _c("div", { staticClass: "data_list_wrapper" }, [
          _c("span", { staticClass: "data_need" }, [_vm._v("所需资料：")]),
          _vm.material && _vm.material.length > 0
            ? _c(
                "div",
                { staticClass: "data_list" },
                _vm._l(_vm.material, function(item, index) {
                  return _c("div", { key: index, staticClass: "data_item" }, [
                    _c("span", { staticClass: "data_title" }, [
                      _vm._v(_vm._s(item.fileName))
                    ]),
                    item.ocrStatus === 0 &&
                    (item.fileType === "company_bussiness_license" ||
                      item.fileType === "company_identity_copy" ||
                      item.fileType === "DRA-filer-listing-apply" ||
                      item.fileType === "DRA-filer-member-apply")
                      ? _c("div", { staticClass: "orc_item" }, [
                          _c(
                            "div",
                            {
                              staticClass: "btn_ocr",
                              on: {
                                click: function($event) {
                                  return _vm.gotoOCR(
                                    item.fileAddr,
                                    item.fileName
                                  )
                                }
                              }
                            },
                            [_vm._v("文字识别")]
                          )
                        ])
                      : item.fileType === "company_credit_report" ||
                        item.fileType === "company_credit_report" ||
                        item.fileType === "company_new_bylaws" ||
                        item.fileType === "company_year_report_data"
                      ? _c(
                          "div",
                          { staticClass: "down_and_up_load" },
                          [
                            item.fileType === "company_credit_report"
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "btn_ocr",
                                    attrs: { href: _vm.creditReport }
                                  },
                                  [_vm._v("下载模板")]
                                )
                              : item.fileType === "company_new_bylaws"
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "btn_ocr",
                                    attrs: { href: _vm.newBylaws }
                                  },
                                  [_vm._v("下载模板")]
                                )
                              : item.fileType === "company_year_report_data"
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "btn_ocr",
                                    attrs: { href: _vm.yearReportData }
                                  },
                                  [_vm._v("下载模板")]
                                )
                              : _vm._e(),
                            _c(
                              "el-upload",
                              {
                                attrs: {
                                  action: _vm.uploadFileUrl,
                                  multiple: "",
                                  name: "file",
                                  headers: _vm.headers,
                                  data: _vm.params(item.fileType),
                                  limit: 1,
                                  "on-error": _vm.uploadError,
                                  "on-success": _vm.uploadSuccess
                                }
                              },
                              [
                                _c("div", { staticClass: "btn_ocr" }, [
                                  _vm._v("导入数据")
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "orc_item" }, [
                      _c(
                        "div",
                        {
                          staticClass: "btn_ocr",
                          on: {
                            click: function($event) {
                              return _vm.viewResults(
                                item.fileType,
                                item.fileName
                              )
                            }
                          }
                        },
                        [_vm._v("查看结果")]
                      )
                    ])
                  ])
                }),
                0
              )
            : _c("div", { staticClass: "data_list" }, [_vm._v("无")])
        ])
      ]),
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeIndex,
            callback: function($$v) {
              _vm.activeIndex = $$v
            },
            expression: "activeIndex"
          }
        },
        _vm._l(_vm.projectList, function(item, index) {
          return _c(
            "el-tab-pane",
            { key: index, attrs: { label: item.demandName, name: index + "" } },
            [_c("Info", { attrs: { dataList: item.dataList } })],
            1
          )
        }),
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.title, visible: _vm.dialogVisible, width: "80%" },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _vm.fileType === "company_credit_report"
            ? _c("credit-reports", {
                attrs: {
                  "dialog-visible": _vm.dialogVisible,
                  "file-type": _vm.fileType,
                  "company-or-personal": _vm.info.names
                }
              })
            : _vm.fileType === "company_year_report_data"
            ? _c("financial-statement", {
                attrs: {
                  "dialog-visible": _vm.dialogVisible,
                  "file-type": _vm.fileType,
                  "company-or-personal": _vm.info.names
                }
              })
            : _vm.fileType === "company_new_bylaws"
            ? _c("constitution", {
                attrs: {
                  "dialog-visible": _vm.dialogVisible,
                  "file-type": _vm.fileType,
                  "company-or-personal": _vm.info.names
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("企业信息")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("企业资料")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }