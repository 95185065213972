var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "identification_main" }, [
    _c("div", { staticClass: "table_main" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "table_body" },
        _vm._l(_vm.data.data, function(item, index) {
          return _c("div", { key: index, staticClass: "content_box" }, [
            _vm._v(_vm._s(item.value))
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "table_header" }, [
      _c("div", { staticClass: "title_box" }, [_vm._v("企业名称")]),
      _c("div", { staticClass: "title_box" }, [_vm._v("中征码")]),
      _c("div", { staticClass: "title_box" }, [_vm._v("统一社会信用代码")]),
      _c("div", { staticClass: "title_box" }, [_vm._v("纳税人识别号（地税）")]),
      _c("div", { staticClass: "title_box" }, [_vm._v("机构信用代码")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }