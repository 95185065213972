
  import { Component, Vue } from 'vue-property-decorator'
  import QrCode from '@/components/QrCode.vue'
  import UploadBase from '@/components/UploadBase64/index.vue'
  import upLoadFileBtn from '@/components/upLoadFileBtn/index.vue'
  import Info from '@/views/project-management/ocr/info.vue'
  import { selectBusinessDetailsById, selectByParam, uploadFileUrl } from '@/api/filing'
  import { localStore } from '@/utils/data-management'
  import StringUtils from '@/utils/strRandom'
  import md5 from 'js-md5'
  import CreditReports from '@/views/project-management/ocr/table/credit-reports/CreditReports.vue'
  import FinancialStatement
    from '@/views/project-management/ocr/table/financial-statement/FinancialStatement.vue'
  import Constitution from '@/views/project-management/ocr/table/constitution/Constitution.vue'

  @Component({
    components: {
      FinancialStatement,
      QrCode,
      UploadBase,
      upLoadFileBtn,
      Info,
      CreditReports,
      Constitution
    }
  })

  export default class OcrDetail extends Vue {
    private loading = false
    private info: any = {}
    private material: any = null
    private projectList: any = null
    private dataList: any = []
    private activeIndex: any = null // 当前 tab 选项
    private newBylaws: any = null
    private yearReportData: any = null
    private creditReport: any = null
    private dialogVisible: any = false
    private fileType: any = ''
    private title: any = ''

    private headers: any = ''
    private uploadFileUrl = uploadFileUrl

    public params(fileType: any): Object {
      let dataObj: any = {
        fileType: fileType,
        companyOrPersonal: this.info.names
      }
      return dataObj
    }

    private created() {
      const zctToken = localStore.get('zctToken')
      const timestamp = new Date().getTime() + ''
      const nonce = StringUtils.randomStr(32)
      const ZCT_SECRET = '21fa6sd1f95w1133edsafas6'
      const encrptString = `${timestamp}.${ZCT_SECRET}.${nonce}`
      const sign = md5(encrptString)
      this.headers = {
        sign,
        timestamp,
        nonce,
        zctToken: zctToken
      }

      let downloadUrlArr: any = this.$route.params.downloadUrlArr
      if (downloadUrlArr && downloadUrlArr.length > 0) {
        downloadUrlArr.forEach((item: any) => {
          if (item.fileType === 'company_new_bylaws') {
            this.newBylaws = item.downloadUrl
          } else if (item.fileType === 'company_year_report_data') {
            this.yearReportData = item.downloadUrl
          } else if (item.fileType === 'company_credit_report') {
            this.creditReport = item.downloadUrl
          }
        })
      }
      if (this.$route.params && this.$route.params.id) {
        this.getDetailData()
      }
    }

    private async getDetailData() {
      this.loading = true
      const params = {
        id: this.$route.params.id
      }
      selectBusinessDetailsById(params as any)
        .then((res) => {
          this.info = res.data.data.info
          this.material = res.data.data.material
          this.projectList = res.data.data.projectList
          this.activeIndex = '0'
          this.dataList = res.data.data.projectList.dataList
        })
        .finally(() => {
          this.loading = false
        })
    }

    private viewResults(fileType: any, fileName: any) {
      debugger
      this.fileType = fileType
      this.title = fileName
      const params = {
        fileType: this.fileType,
        companyOrPersonal: this.info.names
      }
      selectByParam(params as any)
        .then((res) => {
          if (res.data.data) {
            this.dialogVisible = true
          } else {
            this.dialogVisible = false
            this.$message.warning('暂无数据可查看')
          }
        })
        .finally(() => {
        })
    }

    private gotoOCR(fileAddr: any, fileName: any) {
      this.$router.push({
        name: 'Operation', params: {
          fileAddr: fileAddr,
          fileName: fileName
        }
      })
    }

    public uploadSuccess(response: any, file: any, fileList: any) {
      console.log('uploadSuccess :' + response)
    }

    public uploadError(err: any, file: any, fileList: any) {
      console.log('uploadError :' + err)
    }
  }
