import { render, staticRenderFns } from "./Constitution.vue?vue&type=template&id=63e6d73f&scoped=true&"
import script from "./Constitution.vue?vue&type=script&lang=ts&"
export * from "./Constitution.vue?vue&type=script&lang=ts&"
import style0 from "./Constitution.vue?vue&type=style&index=0&id=63e6d73f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63e6d73f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\liu66Work\\work\\assets-admin-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('63e6d73f')) {
      api.createRecord('63e6d73f', component.options)
    } else {
      api.reload('63e6d73f', component.options)
    }
    module.hot.accept("./Constitution.vue?vue&type=template&id=63e6d73f&scoped=true&", function () {
      api.rerender('63e6d73f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/project-management/ocr/table/constitution/Constitution.vue"
export default component.exports