var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "constitution_main" }, [
    _c("p", [_vm._v("认缴情况")]),
    _c("div", { staticClass: "table_main" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "table_body" },
        _vm._l(_vm.dataList, function(item, index) {
          return _c("div", { key: index, staticClass: "table_row" }, [
            _c("div", { staticClass: "box" }, [_vm._v(_vm._s(item.name))]),
            _c("div", { staticClass: "box" }, [
              _vm._v(_vm._s(item.contributionAmount))
            ]),
            _c("div", { staticClass: "box" }, [
              _vm._v(_vm._s(item.contributionTime))
            ]),
            _c("div", { staticClass: "box" }, [
              _vm._v(_vm._s(item.contributionWay))
            ])
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "table_header" }, [
      _c("div", { staticClass: "title_box" }, [_vm._v("股东姓名或名称")]),
      _c("div", { staticClass: "title_box" }, [_vm._v("认缴出资数额（万元）")]),
      _c("div", { staticClass: "title_box" }, [_vm._v("出资期限")]),
      _c("div", { staticClass: "title_box" }, [_vm._v("出资方式")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }